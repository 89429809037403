'use strict';

import { handleActions } from 'redux-actions';
import {
  startAmtKvm,
  stopAmtKvm,
} from './amt-redux-actions';

const initialState = {
  active: false,
  data: null,
};

export const amtKvm = handleActions({
  [startAmtKvm] (prevState, action) {
    return {
      ...prevState,
      active: true,
      data:   action.payload,
    };
  },
  [stopAmtKvm] (prevState) {
    return {
      ...prevState,
      active: false,
      data:   null,
    };
  },
}, initialState);
